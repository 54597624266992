export const SIGN_IN_FLOW_FORGOT_PASSWORD_ERROR = 'AADB2C90118';
export const CANCEL_FLOW_ERROR = 'AADB2C90091';

export const API_URL = `${process.env.NEXT_PUBLIC_API_URL}`;
export const MIT_BOI_URL = `${process.env.NEXT_PUBLIC_API_URL}/scom`;
export const AUTH_URL = `${process.env.NEXT_PUBLIC_NEXTAUTH_URL}`;
export const BC365_URL = `${process.env.NEXT_PUBLIC_BC365_URL}`;
export const CUSTOMER_ENGAGEMENT_URL = `${process.env.NEXT_PUBLIC_CUSTOMER_ENGAGEMENT_URL}`;

export const TOKEN_COOKIE_NAME = 'next-auth.session-token';
export const REDIRECT_URL_COOKIE_NAME = 'boi-return-url';

export const USER_FLOW = 'B2C_1_signup_signin';
export const USER_FLOW_SIGNUP = 'B2C_1_signup';

export const TENANT_HOST_NAME = `${process.env.NEXT_PUBLIC_AZURE_TENANT_NAME}.b2clogin.com`;
export const SIGN_IN_FLOW_ACCESS_TOKEN_REQUEST_URL = `https://${TENANT_HOST_NAME}/${process.env.NEXT_PUBLIC_AUTH_TENANT_NAME}.onmicrosoft.com/${USER_FLOW}/oauth2/v2.0/token?grant_type=authorization_code&client_id=${process.env.NEXT_PUBLIC_AZURE_CLIENT_ID}&scope=${process.env.NEXT_PUBLIC_AUTH_SCOPE}&redirect_uri=https://jwt.ms&client_secret=${process.env.AUTH_CLIENT_SECRET}&code=`;
export const FORGOT_PASSWORD_URL = `https://${TENANT_HOST_NAME}/indeksretailb2c.onmicrosoft.com/B2C_1_Web_Password_Reset/oauth2/v2.0/authorize?response_type=code+id_token&response_mode=form_post&redirect_uri=${AUTH_URL}/api/auth/callback/forgot&scope=offline_access%20openid%20https%3A%2F%2Findeksretailb2c.onmicrosoft.com%2Fapi%2FApi.Read&client_id=${process.env.NEXT_PUBLIC_AZURE_CLIENT_ID}`;
export const LOGIN_URL = `https://${TENANT_HOST_NAME}/${process.env.NEXT_PUBLIC_AUTH_TENANT_NAME}.onmicrosoft.com/B2C_1_signup_signin/oauth2/v2.0/authorize?response_type=code+id_token&response_mode=form_post&redirect_uri=${AUTH_URL}/api/auth/callback/azure-ad-b2c&scope=${process.env.NEXT_PUBLIC_AUTH_SCOPE}&client_id=${process.env.NEXT_PUBLIC_AZURE_CLIENT_ID}`;
export const SIGNUP_URL = `https://${TENANT_HOST_NAME}/${process.env.NEXT_PUBLIC_AUTH_TENANT_NAME}.onmicrosoft.com/${USER_FLOW_SIGNUP}/oauth2/v2.0/authorize?response_type=code+id_token&response_mode=form_post&redirect_uri=${AUTH_URL}/api/auth/callback/azure-ad-b2c&scope=${process.env.NEXT_PUBLIC_AUTH_SCOPE}&client_id=${process.env.NEXT_PUBLIC_AZURE_CLIENT_ID}`;
export const SIGNUP_URL_GOOGLE = `https://${TENANT_HOST_NAME}/${process.env.NEXT_PUBLIC_AUTH_TENANT_NAME}.onmicrosoft.com/${USER_FLOW_SIGNUP}/oauth2/v2.0/authorize?response_type=code+id_token&response_mode=form_post&domain_hint=google.com&redirect_uri=${AUTH_URL}/api/auth/callback/azure-ad-b2c&scope=${process.env.NEXT_PUBLIC_AUTH_SCOPE}&client_id=${process.env.NEXT_PUBLIC_AZURE_CLIENT_ID}`;
export const SIGNUP_URL_FACEBOOK = `https://${TENANT_HOST_NAME}/${process.env.NEXT_PUBLIC_AUTH_TENANT_NAME}.onmicrosoft.com/${USER_FLOW_SIGNUP}/oauth2/v2.0/authorize?response_type=code+id_token&response_mode=form_post&domain_hint=facebook.com&redirect_uri=${AUTH_URL}/api/auth/callback/azure-ad-b2c&scope=${process.env.NEXT_PUBLIC_AUTH_SCOPE}&client_id=${process.env.NEXT_PUBLIC_AZURE_CLIENT_ID}`;

export const LOGOUT_URL = `https://${TENANT_HOST_NAME}/indeksretailb2c.onmicrosoft.com/B2C_1_signup_signin/oauth2/v2.0/logout?post_logout_redirect_uri=${AUTH_URL}/auth/signout`;

export enum AuthCallbackStatus {
    Error = 'error',
    Succes = 'succes',
    SignupSuccess = 'signup-succes',
    SignupError = 'signup-error',
}
